import styled from 'styled-components';
import { Verbatim } from './Verbatim';
import { Slider, Slide } from './Slider';
import { useDocument } from '../../../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../../../firebase';
import { respondToMin, respondToMax } from '../../../styled/rwd';

type Order = string[];

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}
interface CommonData {
	order: Order;
}

const defaultCommonData: CommonData = {
	order: []
};

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

const Container = styled.section`
	${respondToMax('sm')} {
		--width: 100%;
		--padding: 0 var(--page-side-gutter);
	}
	${respondToMin('md')} {
		--width: 640px;
		--padding: 0;
	}

	box-sizing: border-box;
	width: var(--width);
	margin: 0 auto 32px;
	padding: var(--padding);
`;

const Title = styled.h2`
	margin: 0 0 48px;
	color: #262626;
	font-size: var(--section-font-size);
	font-weight: normal;
	text-transform: uppercase;
	text-align: center;
`;

export function Verbatims({ res, className }: Props) {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} data-testid="verbatims">
		<Title>{localized.heading}</Title>
		{common.order.length > 0 &&
			<Slider data-testid="slider">
				{common.order.map((id: any) =>
					<Slide key={id} className="slide" data-testid="slide">
						<Verbatim res={{ base: doc(res.items, id), localized: doc(res.items, id, 'localized/pl-PL') }} />
					</Slide>
				)}
			</Slider>
		}
	</Container>
}