import styled from 'styled-components';
import { Button } from '@hamrolt/components';
import { slidesBase, slideBase, useSlider } from '../Slider';
import { respondToMin, respondToMax } from '../../styled/rwd';

const Container = styled.div`
	${respondToMax('sm')} {
		--grid-template-areas: "slides slides slides" "prev controls next";
	}
	${respondToMin('md')} {
		--grid-template-areas: "prev slides next" "controls controls controls";
	}
	
	display: grid;
	grid-gap: 24px;
	align-items: center;
	grid-template-areas: var(--grid-template-areas);
`;

const PrevSlide = styled(Button)`
	grid-area: prev;
	${({ theme }) => theme.iconButton({ icon: { name: 'navigate_before', color: theme.palette.primary.main } })};
`;

const NextSlide = styled(Button)`
	grid-area: next;
	${({ theme }) => theme.iconButton({ icon: { name: 'navigate_next', color: theme.palette.primary.main } })};
`;

export const Slides = styled.div`
	grid-area: slides;
	${slidesBase};
`;

export const Slide = styled.div`
	display: grid;
    align-items: end;
	${slideBase};
`;

const SlideSelectorGroup = styled.div`
	grid-area: controls;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SlideSelector = styled.button<{ $active: boolean }>`
	box-sizing: border-box;
  display: block;
  width: 8px;
  height: 8px;
  margin: 4px;
	padding: 0;
  background-color: ${({ $active }) => $active ? '#7f7f7f' : '#ccc'};
	border: none;
	border-radius: 50%;
	outline: none;
	cursor: pointer;

	&:hover {
		background-color: #7f7f7f;
	}
`;

export const Slider = ({ children }: any) => {
	const { sliderRef, slider, currentSlide } = useSlider({ slidesCount: children.length });

	return <Container>
		<PrevSlide onClick={(e) => { e.stopPropagation(); slider.prev(); }}>poprzedni</PrevSlide>
		<Slides ref={sliderRef} data-testid="slider">
			{children}
		</Slides>
		<NextSlide onClick={(e) => { e.stopPropagation(); slider.next(); }}>następny</NextSlide>
		<SlideSelectorGroup>
			{children.map((_: any, index: number) => {
				return (
					<SlideSelector
						key={`slide-selector-${index}`}
						onClick={() => {
							slider.moveToSlideRelative(index);
						}}
						$active={currentSlide === index}
					/>
				);
			})}
		</SlideSelectorGroup>
	</Container>
}