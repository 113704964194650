import { css } from 'styled-components';
import { getIcon, IconNames } from './icons';

export interface ButtonProps {
  variant?: 'text' | 'outlined' | 'contained';
  size?: 'small' | 'medium' | 'large';
  color?: 'default' | 'primary' | 'secondary';
  iconStart?: {
    name: IconNames;
    color?: string;
  },
  iconEnd?: {
    name: IconNames;
    color?: string;
  }
}

const iconSizes = { small: '18px', medium: '20px', large: '22px' };
const iconMargins = { small: '4px', medium: '10px', large: '8px' };

export const button = (initialProps: ButtonProps) => {
  const props = Object.assign({ variant: 'text', size: 'medium', color: 'default' }, initialProps);
  const backgrounds = [];

  const sizes = {
    small: css`
      padding: 4px ${props.iconEnd ? '29px' : '5px'} 4px ${props.iconStart ? '29px' : '5px'};
      font-size: 0.8125rem;
      line-height: 1.75;
    `,
    medium: css`
      padding: 5px ${props.iconEnd ? '37px' : '15px'} 5px ${props.iconStart ? '37px' : '15px'};
      font-size: 0.875rem;
      line-height: 1.75;
    `,
    large: css`
      padding: 10px 20px;
      font-size: 0.9375rem;
      line-height: 1.75;
    `
  }

  const variants = {
    text: css`
      border: none;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `,
    outlined: css`
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: transparent;

      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
    `,
    contained: css`
      color: ${({ theme }) => theme.palette[props.color].contrastText};
      border: none;
      background-color: ${({ theme }) => theme.palette[props.color].main};
      box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

      &:hover {
        background-color: ${({ theme }) => theme.palette[props.color].light};
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
      }
    `
  }

  if (props.iconStart) {
    backgrounds.push(`url("${getIcon(props.iconStart.name, props.iconStart.color)}") no-repeat left ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }
  if (props.iconEnd) {
    backgrounds.push(`url("${getIcon(props.iconEnd.name, props.iconEnd.color)}") no-repeat right ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }

  return css<{ selected: boolean }>`
    ${variants[props.variant]};
    ${sizes[props.size]};
    ${backgrounds.length > 0 && `background: ${backgrounds.join(',')}`};

		position: relative;
		color: inherit;
		text-align: center;
		text-decoration: none;
		overflow: hidden;
		border-radius: 4px;
		background-color: ${({ selected }) => selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent'};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
								box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
								border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		cursor: pointer;
		outline: none;
  `;
};

export const buttonContained = (initialProps: ButtonProps) => {
  const backgrounds = [];
  const props = Object.assign({ size: 'medium', color: 'default' }, initialProps);

  const sizes = {
    small: css`
      padding: 4px ${props.iconEnd ? '29px' : '5px'} 4px ${props.iconStart ? '29px' : '5px'};
    `,
    medium: css`
      padding: 5px ${props.iconEnd ? '37px' : '15px'} 5px ${props.iconStart ? '37px' : '15px'};
    `,
    large: css`
      padding: 10px 20px;
    `
  }

  if (props.iconStart) {
    backgrounds.push(`url("${getIcon(props.iconStart.name, props.iconStart.color)}") no-repeat left ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }
  if (props.iconEnd) {
    backgrounds.push(`url("${getIcon(props.iconEnd.name, props.iconEnd.color)}") no-repeat right ${iconMargins[props.size]} center / ${iconSizes[props.size]}`);
  }

  return css<{ selected: boolean }>`
    ${sizes[props.size]};
		position: relative;
		color: ${({ theme }) => theme.palette[props.color].contrastText};
		font-size: inherit;
		text-align: center;
		text-decoration: none;
		overflow: hidden;
		border-radius: 2px;
		border: none;
    ${backgrounds.length > 0 && `background: ${backgrounds.join(',')}`};
		background-color: ${({ theme }) => theme.palette[props.color].main};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		cursor: pointer;
		outline: none;

    &:hover {
      background-color: ${({ theme }) => theme.palette[props.color].light};
    }
  `;
};