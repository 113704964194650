import React from 'react';
import styled, { css } from 'styled-components';
import { respondToMin, respondToMax } from '../styled/rwd';

interface SideBarProps {
	open: boolean;
	children: React.ReactNode;
	onClose: () => void;
}

const elevation0 = css`
  box-shadow: none;
`;

const elevation16 = css`
  box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2), 0px 16px 24px 2px rgba(0,0,0,0.14), 0px 6px 30px 5px rgba(0,0,0,0.12);
`;

const Drawer = styled.aside<{ $open: boolean, onClose: () => void }>`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 256px;
  padding-bottom: 8px;
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  overflow-y: auto;

	${respondToMax('sm')} {
    z-index: 1301;
    transform: translateX(${({ $open }) => $open ? 'none' : '-100%'});
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    ${({ $open }) => $open ? elevation16 : elevation0};
  }

	${respondToMin('md')} {
    z-index: 100;
    padding-top: 80px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const SideBar = ({ open, children, onClose }: SideBarProps) => {

	return <>
		<Drawer $open={open} onClose={onClose}>
			{children}
		</Drawer>
		{open && <Backdrop onClick={onClose} />}
	</>;
};