import styled from 'styled-components';
import { Image as ImageBase } from '@hamrolt/components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { DocumentReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

type Order = string[];

interface CommonData {
	order: Order;
	image: FileData;
}

interface LocalizedData {
	heading: string;
	caption: string;
	imageAlt: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	caption: '',
	imageAlt: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Container = styled.div`
	${respondToMax('sm')} {
		--image-width: 100%;
		--image-margin: 40px 0;
	}
	${respondToMin('md')} {
		--image-width: 60%;
		--image-margin: 104px auto;
	}
`;

const Header = styled.header`
	padding-top: 48px;
	padding-bottom: 48px;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const Title = styled.h2`
	display: block;
	width: 100%;
	margin: 0;
	color: ${({ theme }) => theme.palette.primary.contrastText};
	font-size: var(--section-font-size);
	font-weight: normal;
	text-transform: uppercase;
	text-align: center;
`;

const Figure = styled.figure`
	margin: 0;
`;

const Image = styled(ImageBase)`
	box-sizing: border-box;
	display: block;
	width: var(--image-width);
	margin: var(--image-margin);
`;

const Caption = styled.figcaption`
	position: absolute;
	bottom: 0;
	${({ theme }) => theme.typography.heading4};
	color: #fff;
	text-transform: uppercase;
`;

const defaultCommonData: CommonData = {
	order: [],
	image: fileDefaultData
};

export const Banner = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container>
		<Header>
			<Title>{localized.heading}</Title>
		</Header>
		<Figure className={className}>
			<Image src={common.image.url} alt={localized.imageAlt} />
			<Caption>{localized.caption}</Caption>
		</Figure>
	</Container>
}