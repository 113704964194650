import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/youtube';
import { respondToMin, respondToMax } from '../../styled/rwd';

interface Props {
    heading: string;
    url: string;
    descriptionHTML: string;
}

const Container = styled.div`
    ${respondToMax('sm')} {
		--columns: 100%;
		--rows: auto auto;
	}

	${respondToMin('md')} {
		--columns: 594px 446px;
		--rows: 334px;
	}

	display: grid;
	grid-gap: 48px;
	grid-template-columns: var(--columns);
	grid-template-rows: var(--rows);
	justify-content: center;
`;

const Video = styled(ReactPlayer)`
	display: block;
	margin: 0 auto;
`;

const Texts = styled.div`
	margin: 0 var(--page-side-gutter);
`;

const Heading = styled.h2`
	position: relative;
	padding: 0 0 24px;
	margin: 0 0 24px;
	color: #636363;
	font-size: 1.6rem;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0;
		width: 32px;
		border-bottom: 1px solid #000;
	}
`;

const Richtext = styled.div`
	${({ theme }) => theme.richtext};
	margin-bottom: 40px;
`;

export const VideoArticle = ({ heading, url, descriptionHTML }: Props) =>
    <Container>
        <Video
            width="100%"
            height="100%"
            url={url}
            config={{
                youtube: {
                    playerVars: { controls: 0, color: 'white', rel: 0, showinfo: 0, modestbranding: 1 }
                }
            }}
        />
        <Texts>
            <Heading>{heading}</Heading>
            <Richtext dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
        </Texts>
    </Container>