import { css } from 'styled-components';

//TODO typing
export const textarea = (Label: any, Input: any) => css`
	${Label} {
		display: block;
    margin-bottom: ${({theme}) => theme.spacing.sm};
	}

	${Input} {
		box-sizing: border-box;
		resize: none;
		width: 100%;
		margin: 0;
		font: inherit;
		padding: 18px 14px;
		border-radius: 4px;
		border: 1px solid grey;
	}
`;