import styled from 'styled-components';
import { slidesBase, slideBase, useSlider } from "./Slider";
import { Slide as HeroSlide } from '../components/Slide';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';

type Order = string[];

interface CommonData {
	order: Order;
}

interface Props {
	res: {
		base: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const Container = styled.div`
`;

const defaultCommonData: CommonData = {
	order: []
};

export const Slides = styled.div`
	${slidesBase};
`;

export const Slide = styled.div`
	${slideBase};
`;

export const Slider = ({ children }: any) => {
	const { sliderRef } = useSlider({ slidesCount: children.length });

	return <Slides ref={sliderRef} data-testid="slider">
		{children}
	</Slides>
}

export const Hero = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);

	return <Container className={className}>
		{common.order.length > 0 &&
			<Slider>
				{common.order.map((id: string) =>
					<HeroSlide key={id} res={{ base: doc(res.items, id), localized: doc(res.items, id, 'localized/pl-PL') }} />
				)}
			</Slider>
		}
	</Container>
}