import React from 'react';
import { db, doc, query, collection, where } from './firebase';
import { useAllDocs } from './hooks/useAllDocs';
import { useDocument } from './hooks/useDocument';
import { FileData, fileDefaultData } from './hooks/useFile';

export type SocialChannelName = 'linkedin' | 'youtube' | 'instagram' | 'facebook';

export type SocialChannel = {
    label: string;
    published: boolean;
    url: string;
};

interface Props {
    children: React.ReactNode;
}

interface CommonData {
    socialChannels: Record<string, SocialChannel>;
    contactData: {
        email: string;
        phone: string;
        imageContact: FileData;
        imageProduct: FileData;
    };
}

const defaultCommonData: CommonData = {
    socialChannels: {},
    contactData: {
        email: '',
        phone: '',
        imageContact: fileDefaultData,
        imageProduct: fileDefaultData
    }
};

export const AppSettingsContext = React.createContext(defaultCommonData);

const socialMediaQuery = query(collection(db, 'settings/socialMedia/items'), where('published', '==', true));
const baseContactDataRef = doc(db, 'about/details');
const localizedContactDataRef = doc(db, 'about/details/localized/pl-PL');

export const AppSettings = ({ children }: Props) => {
    const socialChannels = useAllDocs(socialMediaQuery, {});
    const { data: baseContactData } = useDocument(baseContactDataRef, { imageContact: { src: '' }, imageProduct: { src: '' } });
    const { data: localizedContactData } = useDocument(localizedContactDataRef, defaultCommonData.contactData);

    return <AppSettingsContext.Provider value={{
        socialChannels,
        contactData: {
            ...baseContactData,
            ...localizedContactData
        }
    }}>
        {children}
    </AppSettingsContext.Provider>
};