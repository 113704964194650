import { css } from 'styled-components';

export type Category = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'button' | 'caption' | 'overline';
export type Weight = 'light' | 'regular' | 'medium';

const defs: Record<Category, { weight: Weight, size: number, spacing: number }> = {
  h1: { weight: 'light', size: 96, spacing: -1.5 },
  h2: { weight: 'light', size: 60, spacing: -0.5 },
  h3: { weight: 'regular', size: 48, spacing: 0 },
  h4: { weight: 'regular', size: 34, spacing: 0.25 },
  h5: { weight: 'regular', size: 24, spacing: 0 },
  h6: { weight: 'medium', size: 20, spacing: 0.15 },
  subtitle1: { weight: 'regular', size: 16, spacing: 0.15 },
  subtitle2: { weight: 'medium', size: 14, spacing: 0.1 },
  body1: { weight: 'regular', size: 16, spacing: 0.5 },
  body2: { weight: 'regular', size: 14, spacing: 0.25 },
  button: { weight: 'medium', size: 14, spacing: 1.25 },
  caption: { weight: 'regular', size: 12, spacing: 0.4 },
  overline: { weight: 'regular', size: 10, spacing: 1.5 }
};

const size = 16;
const ratio = 0.0625;

export const getTypography = (category: Category) => css`
	font-family: Roboto, sans-serif;
	font-size: ${defs[category].size * ratio}rem;
	letter-spacing: ${defs[category].spacing / size}rem;
`;

const heading1 = css`
	font-family: 'Montserrat';
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
`;

const heading2 = css`
	font-family: 'Montserrat';
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
`;

const heading3 = css`
	font-family: 'Montserrat';
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
`;

const heading4 = css`
	font-family: 'Montserrat';
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
`;

const heading5 = css`
	font-family: 'Montserrat';
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
`;

const heading6 = css`
	font-family: 'Montserrat';
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
`;

const subtitle1 = css`
	font-family: 'Montserrat';
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
`;

const subtitle2 = css`
	font-family: 'Montserrat';
  font-size: .875rem;
  line-height: 1.375rem;
  font-weight: 500;
`;

const body1 = css`
	font-family: 'Open Sans';
  font-size: 12px;
  line-height: 1.2;
  font-weight: 400;
`;

const body2 = css`
	font-family: 'Open Sans';
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
`;

const button = css`
	font-family: 'Open Sans';
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  text-transform: uppercase;
`;

const caption = css`
	font-family: 'Open Sans';
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
`;

const overline = css`
  font-size: 0.75rem;
	line-height: 2rem;
  font-weight: 500;
	text-transform: uppercase;
`;

export const typography = {
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  subtitle1,
  subtitle2,
  body1,
  body2,
  button,
  caption,
  overline
};
