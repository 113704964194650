import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// export const cultureCode = localStorage.getItem('cultureCode') || 'pl-PL';
export const cultureCode = 'pl-PL';

i18next
	.use(Backend)
	.use(initReactI18next)
	.init({
		lng: cultureCode,
		debug: false,
		load: 'currentOnly',
		ns: ['contact'],
		defaultNS: 'contact',
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18next;
