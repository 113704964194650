import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppSettingsContext } from '../../AppSettings';
import { Image as ImageBase } from '@hamrolt/components';
import { useDocument } from '../../hooks/useDocument';
import { FileData, fileDefaultData } from '../../hooks/useFile';
import { Payments, Item as PaymentItem } from './Payments';
import { Hero, Width, Background, Direction } from './Hero';
import { Verbatims } from './Verbatims';
import { VideoArticle } from './VideoArticle';
import { ContactDetails } from '../ContactDetails';
import { respondToMin, respondToMax } from '../../styled/rwd';
import { useCollectionOnce } from '../../hooks/useCollection';
import { collection, DocumentReference } from '../../firebase';

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

interface LocalizedData {
	hero: {
		body: string;
		imageAlt: string;
	};
	videoArticle: {
		heading: string;
		descriptionHTML: string;
	};
	heading: string;
	paymentLabel: string;
	paymentUrl: string;
	bodyHTML: string;
	paymentDescriptionHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	hero: {
		body: '',
		imageAlt: ''
	},
	videoArticle: {
		heading: '',
		descriptionHTML: ''
	},
	paymentLabel: '',
	paymentUrl: '',
	heading: '',
	bodyHTML: '',
	paymentDescriptionHTML: ''
};

interface CommonData {
	published: boolean;
	hero: {
		image: FileData;
		width: Width;
		direction: Direction;
		background: Background;
	},
	videoArticle: {
		url: string;
	};
	image: FileData;
	paymentOrder: string[];
	sectionsOrder: string[];
	verbatimsOrder: string[];
}

const defaultCommonData: CommonData = {
	published: false,
	hero: {
		image: fileDefaultData,
		width: '100',
		direction: 'm',
		background: 'none'
	},
	videoArticle: {
		url: ''
	},
	image: fileDefaultData,
	paymentOrder: [],
	sectionsOrder: [],
	verbatimsOrder: []
};

const Container = styled.div`
	display: grid;
	grid-gap: 48px;
`;

const Article = styled.div<{ layout?: string; }>`
	${respondToMax('sm')} {
		--columns: 1fr;
		--areas: "text" "image";
		--text-padding: var(--page-side-gutter);
	}
	${respondToMin('md')} {
		--columns: 500px 500px;
		--areas: "${({ layout }) => layout || 'image text'}";
		--text-margin: 0 0 44px 48px;
		--text-padding: 32px 48px;
	}

	display: grid;
	grid-template-columns: var(--columns);
	grid-template-areas: var(--areas);
	justify-content: center;
`;

const Image = styled(ImageBase)`
	grid-area: image;
	display: block;
	width: 100%;
`;

const RichText = styled.div`
	${({ theme }) => theme.richtext};
	letter-spacing: 0.05rem;
`;

const Text = styled.div`
	grid-area: text;
	color: #fff;
	padding: var(--text-padding);
	background-color: #809acf;
`;

const Title = styled.h2`
	position: relative;
	padding: 0 0 24px;
	margin: 0 0 24px;
	font-size: 30px;
	font-weight: normal;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0;
		width: 32px;
		border-bottom: 1px solid #fff;
	}
`;

const Contact = styled.div`
	${respondToMax('md')} {
		--content-gap: 24px;
	}

	${respondToMin('lg')} {
		--content-gap: 48px;
	}
	
	display: grid;
	grid-gap: var(--content-gap);
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fit,minmax(300px,475px));
`;

const ContactImage = styled.img`
	width: 100%;
`;

export const ProductDetails = ({ res, className }: Props) => {
	const { contactData } = useContext(AppSettingsContext);
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const { documents: sections } = useCollectionOnce(collection(res.base, 'sections'));
	const { documents: verbatims } = useCollectionOnce(collection(res.base, 'verbatims'));
	const { documents: payment } = useCollectionOnce(collection(res.base, 'payment'));
	const sectionsObj = sections.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr }), {});
	const sectionsEntries = (common.sectionsOrder || []).map(entryId => sectionsObj[entryId]);
	const verbatimsObj = verbatims.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr }), {});
	const verbatimsEntries = (common.verbatimsOrder || []).map(entryId => verbatimsObj[entryId]);
	const paymentObj = payment.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr }), {});
	const paymentVariants = (common.paymentOrder || []).map(paymentId => paymentObj[paymentId]);

	return <Container className={className} data-testid="page">
		{common.hero?.image?.url &&
			<Hero {...common.hero} {...localized.hero} />
		}
		<Article>
			<Text>
				<Title>{localized.heading}</Title>
				<RichText dangerouslySetInnerHTML={{ __html: localized.bodyHTML }} />
			</Text>
			<Image src={common.image.url} />
		</Article>
		{sectionsEntries.map((entry, i) =>
			<Article key={entry.id} layout={i % 2 === 0 ? 'text image' : 'image text'}>
				<Text>
					<RichText dangerouslySetInnerHTML={{ __html: entry.bodyHTML }} />
				</Text>
				<Image src={entry.image.url} />
			</Article>
		)}
		{common.videoArticle &&
			<VideoArticle
				heading={localized.videoArticle.heading}
				descriptionHTML={localized.videoArticle.descriptionHTML}
				url={common.videoArticle.url}
			/>
		}
		{verbatimsEntries.length > 0 &&
			<Verbatims items={verbatimsEntries} />
		}
		{paymentVariants.length > 0 &&
			<Payments items={paymentVariants as PaymentItem[]} />
		}
		<Contact>
			<ContactImage src={contactData.imageProduct.url} />
			<ContactDetails
				heading="Skontaktuj się ze mną, porozmawiajmy."
				phone={contactData.phone}
				email={contactData.email}
			/>
		</Contact>
	</Container>
}