import styled from 'styled-components';
import { Heading } from '@hamrolt/components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { DocumentReference } from '../firebase';

interface LocalizedData {
	heading: string;
	label: string;
	link: string;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	label: '',
	link: '',
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
	material: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData,
	material: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Title = styled(Heading)`
	grid-area: title;
	margin-bottom: 16px;
	margin: 0;
	padding: 0;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	text-transform: uppercase;
`;

const Image = styled.img`
	display: block;
	width: 100%;
`;

const Label = styled.div`
	${({ theme }) => theme.typography.heading6};
	position: absolute;
	top: 0;
	left: 0;
	box-sizing: border-box;
	padding: 4px 12px;
	color: #fff;
`;

const RichText = styled.div`
	${({ theme }) => theme.richtext};
`;

const Container = styled.div`
	display: grid;
	grid-gap: 8px;
	align-content: start;
	justify-items: center;
	grid-template-areas: 'link' 'title';
	grid-template-rows: auto 2rem;
`;

const Link = styled.a`
	grid-area: link;
	color: inherit;
	text-decoration: none;
`;

export const Material = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} >
		<Title>{localized.heading}</Title>
		{localized.label && <Label>{localized.label}</Label>}
		<RichText dangerouslySetInnerHTML={{ __html: localized.bodyHTML }} />
		<Link
			href={localized.link ? localized.link : common.material?.url}
			download={localized.link ? null : true}
		>
			<Image src={common.image.url} />
		</Link>
	</Container>
}