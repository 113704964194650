import { Hero } from '../components/Hero';
import { Products } from '../components/Products';
import { Banner } from '../components/Banner';
import { Materials } from '../components/Materials';
import { db, doc, collection } from '../firebase';

export const MentoringPage = () =>
	<div>
		<Hero res={{ base: doc(db, 'mentoring/hero'), items: collection(db, 'mentoring/hero/items') }} />
		<Products
			section="moja-oferta"
			res={{
				base: doc(db, 'mentoring/offer'), localized: doc(db, 'mentoring/offer/localized/pl-PL'),
				items: collection(db, 'mentoring', 'offer', 'items')
			}}
		/>
		<Banner res={{ base: doc(db, 'mentoring/cooperation'), localized: doc(db, 'mentoring/cooperation/localized/pl-PL') }} />
		<Materials res={{ base: doc(db, 'mentoring/materials'), localized: doc(db, 'mentoring/materials/localized/pl-PL'), items: collection(db, 'mentoring/materials/items') }} />
	</div>