import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import ReactPlayer from 'react-player/youtube';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

interface CommonData {
	published: boolean;
	movieURL: string;
}

const defaultCommonData: CommonData = {
	published: false,
	movieURL: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

interface LocalizedData {
	heading: string;
	subheading: string;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	subheading: '',
	bodyHTML: ''
};

const Container = styled.section`
	${respondToMax('sm')} {
		--columns: 100%;
		--rows: auto auto;
	}

	${respondToMin('md')} {
		--columns: 594px 446px;
		--rows: 334px;
	}

	margin-bottom: 104px;
`;

const Heading = styled.h2`
	margin: 0 0 48px;
	color: #262626;
	font-size: var(--section-font-size);
	text-transform: uppercase;
	text-align: center;
`;

const Subheading = styled.h3`
	position: relative;
	padding: 0 0 24px;
	margin: 0 0 24px;
	color: #636363;
	font-size: 1.6rem;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0;
		width: 32px;
		border-bottom: 1px solid #000;
	}
`;

const Richtext = styled.div`
	${({ theme }) => theme.richtext};
	margin-bottom: 40px;
`;

const Content = styled.div`
	display: grid;
	grid-gap: 48px;
	grid-template-columns: var(--columns);
	grid-template-rows: var(--rows);
	justify-content: center;
`;

const Texts = styled.div`
	margin: 0 var(--page-side-gutter);
`;

const Video = styled(ReactPlayer)`
	display: block;
	margin: 0 auto;
`;

const MoreButton = styled(RouterLink)`
	${({ theme }) => theme.buttonContained()};
`;

export const About = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} data-testid="about">
		<Heading>{localized.heading}</Heading>
		<Content>
			<Video
				width="100%"
				height="100%"
				url={common.movieURL}
				config={{
					youtube: {
						playerVars: { controls: 0, color: 'white', rel: 0, showinfo: 0, modestbranding: 1 }
					}
				}}
			/>
			<Texts>
				<Subheading>{localized.subheading}</Subheading>
				<Richtext dangerouslySetInnerHTML={{ __html: localized.bodyHTML }} />
				<MoreButton to="/o-mnie">więcej</MoreButton>
			</Texts>
		</Content>
	</Container>
};