import { css } from 'styled-components';

export const richtext = css`
	line-height: 1.8;

	a {
		color: inherit;
		&:hover {
			color: #99858f;
		}
	}

	h1 {
		${({ theme }) => theme.typography.heading4};
	}

	h2 {
		${({ theme }) => theme.typography.heading5};
	}

	h3 {
		${({ theme }) => theme.typography.heading5};
	}

	h4 {
		${({ theme }) => theme.typography.heading5};
	}

	ol, 
	ul {
		margin: 0 0 0 16px;
		padding: 0;
		list-style-position: outside;
	}

	li {
		margin: 8px 0;
	}

	p {
		margin: 8px 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;