import styled from 'styled-components';
import { Certificate } from './Certificate';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';
import { respondToMin, respondTo } from '../styled/rwd';

type Order = string[];

interface CommonData {
	order: Order;
}

interface Props {
	res: {
		base: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const defaultCommonData: CommonData = {
	order: []
};

const Container = styled.section`
	${respondTo('xs')} {
		--width: 100%;
		--gap: 64px;
		--columns: repeat(2, 100px);
	}
	${respondTo('sm')} {
		--width: 100%;
		--gap: 80px;
		--columns: repeat(3, 100px);
	}
	${respondToMin('md')} {
		--width: 894px;
		--gap: 96px;
		--columns: repeat(5, 100px);
	}

	width: var(--width);
	margin: 0 auto;
	margin-bottom: 104px;
`;

const Title = styled.h2`
	margin: 0 0 48px;
	color: #262626;
	font-size: var(--section-font-size);
	text-transform: uppercase;
	text-align: center;
`;

const Content = styled.div`
	display: grid;
	grid-gap: var(--gap);
	grid-template-columns: var(--columns);
	justify-content: center;
`;

export const Certificates = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);

	return <Container className={className} data-testid="certificates">
		<Title>Certyfikaty</Title>
		<Content>
			{common.order.length > 0 &&
				common.order.map((id: string) =>
					<Certificate key={id} res={{ base: doc(res.items, id) }} />
				)
			}
		</Content>
	</Container >
};