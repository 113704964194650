import React from 'react';
import styled from 'styled-components';
import { SideBar } from './SideBar';

interface Props {
	open: boolean;
	children: React.ReactNode;
	onOpen: () => void;
	onClose: () => void;
}

const Hamburger = styled.button`
	${({ theme }) => theme.iconButton({ icon: { name: 'menu', color: '#888' } })};
  justify-self: start;
  display: flex;
	width: 24px;
	height: 24px;
  color: inherit;
	border: none;
  box-shadow: none;
`;

const Actions = styled.div`
	display: grid;
	justify-content: end;
	width: 100%;
`;

const CloseButton = styled.button`
	${({ theme }) => theme.iconButton({ icon: { name: 'close', color: '#888' } })};
`;

export const MobileDrawer = ({ open, children, onOpen, onClose }: Props) =>
	<>
		<Hamburger onClick={onOpen} />
		<SideBar
			open={open}
			onClose={onClose}
		>
			<Actions data-testid="actions">
				<CloseButton onClick={onClose}>zamknij</CloseButton>
			</Actions>
			{children}
		</SideBar>
	</>