import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button } from '@hamrolt/components';
import { useLocalStorage } from '../hooks/useLocalStorage';

const Container = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-gap: 16px;
    justify-items: center;
    position: fixed;
    right: 16px;
    left: 16px;
    bottom: 16px;
    z-index: 100;
    padding: 16px;
    color: #fff;
    background-color: rgba(208,178,213,.84);
    overflow: hidden;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
`;

const AgreeButton = styled(Button)`
    ${({ theme }) => theme.buttonContained({ color: 'secondary' })};
	min-width: 64px;
`;

const DisagreeButton = styled(Button)`
    ${({ theme }) => theme.buttonContained({ color: 'secondary' })};
	min-width: 64px;
`;

export const CookieConsent = () => {
    const { value: consent, setValue: setConsent } = useLocalStorage('cookieConsent', '');

    return consent === 'yes' || consent === 'no'
        ? null
        : <Container>
            <div>
                Na tej stronie używamy plików cookie i porównywalnych funkcji do przetwarzania informacji o urzadzeniach końcowych i danych osobowych. Przetwarzanie ma na celu integracji treści, usług zewnętrznych i elementów podmiotów trzecich, analizie statystycznej / pomiarowej.
                <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
            </div>
            <Actions>
                <AgreeButton type='button' onClick={() => setConsent('yes')}>Akceptuję</AgreeButton>
                <DisagreeButton type='button' onClick={() => setConsent('no')}>Nie akceptuję</DisagreeButton>
            </Actions>
        </Container>
};