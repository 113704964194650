import styled from 'styled-components';
import { slidesBase, slideBase, useSlider } from "./Slider";
import { Logo } from '../components/Logo';
import { useDocument } from '../hooks/useDocument';
import { useWindowSize } from '../hooks/useWindowSize';
import { doc, DocumentReference, CollectionReference } from '../firebase';
import { getSize, respondTo, respondToMin } from '../styled/rwd';

type Order = string[];

interface CommonData {
	order: Order;
}

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const defaultCommonData: CommonData = {
	order: []
};

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

const Section = styled.section`
	${respondTo('xs')} {
		--content-width: 280px;
	}

	${respondTo('sm')} {
		--content-width: 584px;
	}

	${respondToMin('lg')} {
		--content-width: 888px;
	}

	margin: 0 auto 104px;
	padding: 48px 0;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const Title = styled.h2`
	margin: 0 0 48px;
	color: #fff;
	font-size: var(--section-font-size);
	text-transform: uppercase;
	text-align: center;
`;

const Content = styled.div`
	width: var(--content-width);
	margin: 0 auto;
`;

export const Slides = styled.div`
	${slidesBase};
`;

export const Slide = styled.div`
	${slideBase};
`;

export const Slider = ({ children, slidesPerView }: any) => {
	const { sliderRef } = useSlider({ slidesCount: children.length, slidesPerView });

	return <Slides ref={sliderRef} data-testid="slider">
		{children}
	</Slides>
}

export const Logotypes = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const windowSize = useWindowSize();

	return <Section className={className}>
		<Title>{localized.heading}</Title>
		<Content>
			{common.order.length > 0 &&
				<Slider
					spacing={24}
					slidesPerView={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6 }[getSize(windowSize.width) || 'xs']}
				>
					{common.order.map((id: string) =>
						<Slide key={id} className="slide" data-testid="slide">
							<Logo key={id} res={{ base: doc(res.items, id) }} />
						</Slide>
					)}
				</Slider>
			}
		</Content>
	</Section >
};