import { useState } from 'react';
import './i18n';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppSettings } from './AppSettings';
import { HomePage } from './pages/Home/Home';
import { MentoringPage } from './pages/Mentoring';
import { CommunicationPage } from './pages/Communication';
import { ProductPage } from './pages/Product';
import { AboutPage } from './pages/About';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { SaleStatute } from './pages/SaleStatute';
import { Navigation } from './components/Navigation';
import { Footer } from './components/Footer';
import { MobileDrawer } from './components/MobileDrawer';
import { CookieConsent } from './components/CookieConsent';
import { ReactComponent as Logo } from './logo.svg';
import { useWindowSize } from './hooks/useWindowSize';
import { respondToMin, respondToMax, isSizeOfMax } from './styled/rwd';

const Container = styled.div`
	${respondToMax('sm')} {
		--header-justify-items: end;
		--nav-auto-flow: row;
		--nav-justify-items: start;
	}
	${respondToMin('md')} {
		--header-justify-items: center;
		--nav-auto-flow: column;
		--nav-justify-items: center;
	}
`;

const Header = styled.header`
	position: sticky;
	top: 0;
	z-index: 10;
	display: grid;
	grid-template-columns: 1fr auto;
	justify-items: var(--header-justify-items);
	align-items: center;
	padding: 24px;
	background-color: #fff;
	box-shadow: 0 1px 2px #ccc;
`;

const Main = styled.main`
	overflow-x: hidden;
`;

const MainNavigation = styled(Navigation)`
	display: grid;
	grid-auto-flow: var(--nav-auto-flow);
	align-items: center;
  justify-items: var(--nav-justify-items);
`;

const LogoLink = styled(Link)`
	justify-self: start;
`;

const links = [
	{ id: 'moja-oferta', label: 'Moja oferta', to: '/moja-oferta' },
	{ id: 'moje-doswiadczenie', label: 'Moje doświadczenie', to: '/moje-doswiadczenie' },
	{ id: 'about', label: 'O mnie', to: '/o-mnie' }
];

export const sectionsMap = {
    'moja-oferta': 'mentoring',
    'moje-doswiadczenie': 'communication'
};

export const App = () => {
	const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
	const windowSize = useWindowSize();

	return <AppSettings>
		<Router>
			<Container>
				<Header>
					<LogoLink to="/"><Logo /></LogoLink>
					{isSizeOfMax(windowSize.width, 'sm')
						? <MobileDrawer
							open={mobileDrawerOpen}
							onOpen={() => setMobileDrawerOpen(true)}
							onClose={() => setMobileDrawerOpen(false)}
						>
							<MainNavigation
								links={links}
								onClick={() => setMobileDrawerOpen(false)}
							/>
						</MobileDrawer>
						: <MainNavigation links={links} />
					}
				</Header>
				<Main>
					<CookieConsent />
					<Switch>
						<Route exact path='/'>
							<HomePage />
						</Route>
						<Route exact path='/moja-oferta'>
							<MentoringPage />
						</Route>
						<Route exact path='/moje-doswiadczenie'>
							<CommunicationPage />
						</Route>
						<Route exact path='/:sectionId/:slug'>
							<ProductPage />
						</Route>
						<Route exact path='/o-mnie'>
							<AboutPage />
						</Route>
						<Route exact path='/polityka-prywatnosci'>
							<PrivacyPolicy />
						</Route>
						<Route exact path='/regulamin-sprzedazy'>
							<SaleStatute />
						</Route>
					</Switch>
				</Main>
				<Footer />
			</Container>
		</Router>
	</AppSettings>
};