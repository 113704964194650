import { css } from 'styled-components';
import { getIcon, IconNames } from './icons';

export interface IconButtonProps {
  size?: 'small' | 'medium';
  color?: 'default' | 'primary' | 'secondary';
  icon: {
    name: IconNames;
    color?: string;
  }
}

export const iconButton = ({ size = 'medium', icon }: IconButtonProps) => {

  const sizes = {
    small: css`
      width: 40px;
      height: 40px;
      padding: 12px;
      font-size: 0.8125rem;
      line-height: 1.75;
    `,
    medium: css`
      width: 48px;
      height: 48px;
      padding: 12px;
      font-size: 0.875rem;
      line-height: 1.75;
    `
  }

  return css<{selected: boolean}>`
		box-sizing: border-box;
		position: relative;
    ${sizes[size]};
    ${({ theme }) => theme.helpers.hideText};
		${({ theme }) => theme.typography.button};
		border: none;
		border-radius: 50%;
    background: ${({ selected }) => selected ? 'rgba(0, 0, 0, 0.08)' : 'transparent'} ${`url("${getIcon(icon.name, icon.color)}") no-repeat center`};
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
              border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		cursor: pointer;
		outline: none;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  `;
};