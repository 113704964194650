import styled from 'styled-components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { respondToMin, respondToMax } from '../styled/rwd';
import { DocumentReference } from '../firebase';

type Direction = 'nw' | 'n' | 'ne' | 'e' | 'm' | 'w' | 'se' | 's' | 'sw';
type Position = 'start' | 'center' | 'end';
export type Definitions = Record<Direction, { h: Position, v: Position }>;
type Background = 'dark' | 'none';

const positionerDefinitions: Definitions = {
	nw: { h: 'start', v: 'start' },
	n: { h: 'center', v: 'start' },
	ne: { h: 'end', v: 'start' },
	e: { h: 'start', v: 'center' },
	m: { h: 'center', v: 'center' },
	w: { h: 'end', v: 'center' },
	se: { h: 'start', v: 'end' },
	s: { h: 'center', v: 'end' },
	sw: { h: 'end', v: 'end' }
};

interface LocalizedData {
	body: string;
	imageAlt: string;
}

const defaultLocalizedData: LocalizedData = {
	body: '',
	imageAlt: ''
};

interface CommonData {
	published: boolean;
	position: Direction;
	width: number;
	background: Background;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	position: 'm',
	width: 100,
	background: 'none',
	image: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Container = styled.div<{ $position: { h: Position, v: Position } }>`
	${respondToMax('sm')} {
		--font-size: 1rem;
		--texts-padding: 7px 35px;
	}

	${respondToMin('md')} {
		--font-size: 2.8rem;
		--texts-padding: 17px 88px;
	}

	display: grid;
	justify-items: ${({ $position }) => $position.h};
	align-items: ${({ $position }) => $position.v};
	position: relative;
`;

const Texts = styled.div<{ $width: number, $background: Background }>`
	box-sizing: border-box;
	position: absolute;
	width: ${({ $width }) => $width}%;
	padding: var(--texts-padding);
	color: #fff;
	background-color: ${({ $background, theme }) => theme.textBackgrounds[$background]};
`;

const Body = styled.div`
	font-size: var(--font-size);
	margin-bottom: 8px;
`;

const Image = styled.img`
	display: block;
	width: 100%;
`;

export const Slide = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} $position={positionerDefinitions[common.position]}>
		{localized.body &&
			<Texts $width={common.width} $background={common.background}>
				<Body>{localized.body}</Body>
			</Texts>
		}
		<Image src={common.image.url} alt={localized.imageAlt} />
	</Container>
};