import styled from 'styled-components';
import { useDocument } from '../hooks/useDocument';
import { DocumentReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

interface LocalizedData {
	heading: string;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
}

const defaultCommonData: CommonData = {
	published: false
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Container = styled.div`
	${respondToMax('sm')} {
		--text-margin: var(--page-side-gutter);
	}
	${respondToMin('md')} {
		--text-margin: 0 24px;
	}
`;

const RichText = styled.div`
	${({ theme }) => theme.richtext};
	letter-spacing: 0.05rem;
`;

const Text = styled.div`
	margin: var(--text-margin);
`;

const Title = styled.h2`
	position: relative;
	padding: 0 0 24px;
	margin: 0 0 24px;
	font-size: 30px;
	font-weight: normal;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0;
		width: 32px;
		border-bottom: 1px solid #000;
	}
`;

export const Article = ({ res, className }: Props) => {
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className}>
		<Text>
			<Title>{localized.heading}</Title>
			<RichText dangerouslySetInnerHTML={{ __html: localized.bodyHTML }} />
		</Text>
	</Container>
}