import { css } from 'styled-components';
import { getIcon } from './icons';
import { palette } from './colors';
import { typography, getTypography } from './typography';
import { elevation } from './elevation';
import { rating } from './rating';
import { richtext } from './richtext';
import { textfield } from './textfield';
import { textarea } from './textarea';
import { button, buttonContained } from './button';
import { iconButton } from './iconButton';

const spacing = { xs: '2px', sm: '4px', md: '8px', lg: '16px', xl: '24px', xxl: '32px' };

const textBackgrounds = {
  dark: 'rgba(128, 154, 207, .9)',
  none: 'transparent'
};

export const theme = {
  palette,
  spacing,
  elevation,
  typography,
  getTypography,
  button,
  buttonContained,
  iconButton,
  rating,
  textfield,
  textarea,
  richtext,
  textBackgrounds,
  link: css`
		color: inherit;
		text-decoration: none;

		&:hover {
			color: ${({ theme }) => theme.palette.primary.main};
			text-decoration: underline;
		}
	`,
  helpers: {
    getIcon,
    hideText: css`
      text-indent: -9999px;
      white-space: nowrap;
      overflow: hidden;
    `,
  },
};
