import {useState, useEffect} from 'react';

interface WindowSize {
	width: number;
	height: number;
}

const defaultWindowSize: WindowSize = {
	width: 0, 
	height: 0 
};

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState<WindowSize>(defaultWindowSize);

	useEffect(() => {
		const handleResize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight });
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}