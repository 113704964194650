import React from 'react';
import styled from 'styled-components';
import { respondTo, respondToMin, respondToMax } from '../../styled/rwd';

export type Item = {
    id: string;
    heading: string;
    subheading: string;
    descriptionHTML: string;
    price: number;
    url: string;
    label: string;
};

interface Props {
    items: Item[];
}

const Container = styled.div`
	${respondToMax('md')} {
		--content-gap: 24px;
	}

	${respondToMin('lg')} {
		--content-gap: 48px;
	}

	display: grid;
	grid-gap: var(--content-gap);
	justify-content: center;
	grid-template-columns: repeat(auto-fit, minmax(300px, 475px));
`;

const Item = styled.div<{ $borderColor: string; }>`
    display: grid;
    grid-template-rows: auto 1fr auto;
    border: 1px solid ${({ $borderColor }) => $borderColor};
`;

const Header = styled.header<{ $bgColor: string; }>`
    padding: 8px;
    color: #fff;
	text-align: center;
    background-color: ${({ $bgColor }) => $bgColor};
`;

const Heading = styled.h3`
	margin: 0;
    text-transform: uppercase;
`;

const Subheading = styled.h4`
	margin: 0;
`;

const Price = styled.div<{ $color: string; }>`
    margin: 8px 0 24px;
    color: ${({ $color}) => $color};
	${({ theme }) => theme.getTypography('h4')};
    text-align: center;
`;

const Body = styled.div`
	box-sizing: border-box;
	margin: var(--body-margin);
	padding: 16px;
    ${({ theme }) => theme.richtext};
`;

const BuyButton = styled.a`
    justify-self: center;
	display: block;
	min-width: 64px;
	${({ theme }) => theme.buttonContained({ size: 'medium', color: 'secondary', iconStart: { name: 'payments', color: '#fff' } })};
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 16px;
`;

const headerColors = ['#b998be', '#809acf'];

export const Payments = ({ items }: Props) =>
    <Container data-testid="payments">
        {items.map((item, i) =>
            <Item key={item.id} $borderColor={headerColors[i]}>
                <Header $bgColor={headerColors[i]}>
                    <Heading>{item.heading}</Heading>
                    <Subheading>{item.subheading}</Subheading>
                </Header>
                <Body dangerouslySetInnerHTML={{ __html: item.descriptionHTML }} />
                <Price $color={headerColors[i]}>{ item.price} zł</Price>
                <BuyButton href={item.url} target="_blank">{item.label}</BuyButton>
            </Item>
        )}
    </Container>