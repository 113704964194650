import { useEffect, useState } from 'react';
import {onSnapshot, setDoc, deleteDoc, DocumentReference, DocumentSnapshot} from '../firebase';

export const useDocument = <T>(docRef: DocumentReference, initialState: T) => {
	const [data, setData] = useState<T>(initialState);

	const update = (values: Partial<T>) => {
		setData(curr => ({...curr, ...values}));
		setDoc(docRef, values, {merge: true});
	};
	const remove = () => deleteDoc(docRef);

	useEffect(() => {
		const unsubscribe = onSnapshot(docRef, (documentSnapshot: DocumentSnapshot) => {
			if (documentSnapshot.exists()) {
				setData(documentSnapshot.data() as T);
			}
		});
		return unsubscribe;
	}, [docRef]);

	return { data, update, remove };
};