import { useEffect, useState } from 'react';
import { getDocs, DocumentData, Query, CollectionReference } from '../firebase';

export const useCollectionQueryOnce = (dbQuery: Query) => {
    const [documents, setDocuments] = useState<DocumentData[]>([]);
    useEffect(() => {
        getDocs(dbQuery).then((result) => {
            const entries = result.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDocuments(entries);
        });
    }, []);

    return {
        documents
    };
};

export const useCollectionOnce = (collection: Query | CollectionReference) => {
    const [documents, setDocuments] = useState<DocumentData[]>([]);
    useEffect(() => {
        getDocs(collection).then((result) => {
            const entries = result.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setDocuments(entries);
        });
    }, []);

    return {
        documents
    };
};