import { useState, useEffect } from 'react';
import { getDocs, Query } from '../firebase';

export const useAllDocs = <T extends Object>(query: Query, initialState: T) => {
    const [data, setData] = useState(initialState);

    useEffect(() => {
        getDocs(query)
            .then(querySnapshot => {
                const data = querySnapshot.docs.reduce((acc, curr) => Object.assign(acc, { [curr.id]: curr.data() }), {} as T);
                setData(data);
            });
    }, [query]);

    return data;
};