import styled from 'styled-components';
import { Hero } from '../../components/Hero';
import { About } from '../../components/About';
import { Certificates } from '../../components/Certificates';
import { Verbatims } from './Verbatims/Verbatims';
import { Logotypes } from '../../components/Logotypes';
import { Posters } from '../../components/Posters';
import { db, doc, collection } from '../../firebase';

const HomeHero = styled(Hero)`
	margin-bottom: 104px;
`;

export const HomePage = () =>
	<div>
		<HomeHero res={{ base: doc(db, 'home', 'hero'), items: collection(db, 'home/hero/items') }} />
		<About res={{ base: doc(db, 'home/about'), localized: doc(db, 'home/about/localized/pl-PL') }} />
		<Certificates res={{ base: doc(db, 'home/certificates'), items: collection(db, 'home/certificates/items') }} />
		<Verbatims res={{ base: doc(db, 'home/verbatims'), localized: doc(db, 'home/verbatims/localized/pl-PL'), items: collection(db, 'home/verbatims/items') }} />
		<Logotypes res={{ base: doc(db, 'home/logotypes'), localized: doc(db, 'home/logotypes/localized/pl-PL'), items: collection(db, 'home/logotypes/items') }} />
		<Posters res={{ base: doc(db, 'home/space'), localized: doc(db, 'home/space/localized/pl-PL'), items: collection(db, 'home/space/items') }} />
	</div>