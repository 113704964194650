import styled from 'styled-components';
import { slugify } from '../../tools';
import { useDocument } from '../../hooks/useDocument';
import { FileData, fileDefaultData } from '../../hooks/useFile';
import { DocumentReference } from '../../firebase';
import { respondToMin, respondToMax } from '../../styled/rwd';

interface LocalizedData {
	heading: string;
	label: string;
	slug: string;
	imageAlt: string;
	teaserHTML: string;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	label: '',
	slug: '',
	imageAlt: '',
	teaserHTML: '',
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
	material: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData,
	material: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	section: string;
	className?: string;
}

const Container = styled.div`
	${respondToMax('sm')} {
		--body-width: auto;
		--body-margin: 0 24px;
	}

	${respondToMin('md')} {
		--body-width: 496px;
		--body-margin: 0;
	}

	display: grid;
	grid-gap: 16px;
	align-content: start;
	justify-items: center;
	grid-template-areas: 'image' 'title' 'description';
`;

const Title = styled.h2`
	margin: 0;
	padding: 0;
	font-family: "Montserrat";
	font-size: 14px;
	font-weight: normal;
	text-align: center;
	text-transform: uppercase;
`;

const Image = styled.img`
	display: block;
	width: 100%;
`;

const ImageContainer = styled.div`
	grid-area: image;
	position: relative;
`;

const Label = styled.div`
	${({ theme }) => theme.typography.heading6};
	position: absolute;
	box-sizing: border-box;
	padding: 4px 12px;
	color: #fff;
`;

const RichText = styled.div`
	height: 100%;
	${({ theme }) => theme.richtext};
	text-align: center;
	letter-spacing: 0.05rem;
	overflow: hidden;
`;

const ImageLink = styled.a`
	display: block;
	text-decoration: none;
`;

const MoreLink = styled.a`
	${({ theme }) => theme.buttonContained()};
	min-width: 64px;
`;

export const Product = ({ res, section, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);
	const slug = localized.slug ? localized.slug : slugify(localized.heading);
	const linkUrl = `/${section}/${slug}-${res.base.id}`;

	return <Container className={className}>
		<Title>{localized.heading}</Title>
		<RichText dangerouslySetInnerHTML={{ __html: localized.teaserHTML }} />
		<ImageContainer>
			<Label>{localized.label}</Label>
			<ImageLink href={linkUrl}>
				<Image src={common.image.url} alt={localized.imageAlt} />
			</ImageLink>
		</ImageContainer>
		<MoreLink href={linkUrl}>Zobacz więcej</MoreLink>
	</Container>
}