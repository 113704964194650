import styled from 'styled-components';
import { useDocument } from '../../../hooks/useDocument';
import { FileData, fileDefaultData } from '../../../hooks/useFile';
import { DocumentReference } from '../../../firebase';
import { respondToMin, respondToMax } from '../../../styled/rwd';

interface LocalizedData {
	author: string;
	body: string;
}

const defaultLocalizedData: LocalizedData = {
	author: '',
	body: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Container = styled.div`
	${respondToMax('sm')} {
		--content-font-size: 1.45rem;
		--author-font-size: 1.3rem;
	}
	${respondToMin('md')} {
		--content-font-size: 1.7rem;
		--author-font-size: 1.5rem;
	}
`;

const Author = styled.p`
	margin: 0;
	font-size: var(--author-font-size);
  text-align: center;
`;

const Content = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
	margin-bottom: 24px;
	font-size: var(--content-font-size);
	text-align: center;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		height: 0;
		width: 150px;
		border-bottom: 1px solid #000;
	}

	p {
		display: inline;
		margin: 0;

		&:before,
		&:after {
			content: '"';
		}
	}
`;

const Image = styled.img`
	display: block;
	width: 104px;
	height: 104px;
	margin: 0 auto 8px;
	border: 4px solid #fff;
	border-radius: 50%;
`;

export const Verbatim = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} data-testid="verbatim">
		<Image src={common.image.url} />
		<Content dangerouslySetInnerHTML={{ __html: `<p>${localized.body}</p>` }} />
		<Author>{localized.author}</Author>
	</Container>
};