import styled from 'styled-components';
import { Poster } from '../components/Poster';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

const Container = styled.section`
	margin-bottom: 80px;

	${respondToMax('xs')} {
		--content-columns: repeat(1, 1fr);
	}

	${respondToMax('md')} {
		--content-gap: 24px;
	}

	${respondToMin('lg')} {
		--content-gap: 48px;
	}

	${respondToMin('sm')} {
		--content-columns: repeat(2, minmax(300px, 475px));
	}
`;

const Title = styled.h2`
	margin: 0 0 48px;
	color: #262626;
	font-size: var(--section-font-size);
	text-transform: uppercase;
	text-align: center;
`;

const Content = styled.div`
	display: grid;
	grid-gap: var(--content-gap);
	justify-content: center;
	grid-template-columns: var(--content-columns);
`;

export const Posters = ({ res, className }: Props) => {
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className}>
		<Title>{localized.heading}</Title>
		<Content>
			<Poster link="/moja-oferta" res={{ base: doc(res.items, 'mentoring'), localized: doc(res.items, 'mentoring/localized/pl-PL') }} />
			<Poster link="/moje-doswiadczenie" res={{ base: doc(res.items, 'communication'), localized: doc(res.items, 'communication/localized/pl-PL') }} />
		</Content>
	</Container>
}