import React, { useState, useEffect } from 'react';
import { css } from 'styled-components';
import { TOptions } from 'keen-slider';
import { useKeenSlider } from 'keen-slider/react';

interface Props extends TOptions {
	slidesCount: number;
	autoScroll?: boolean;
	interval?: number;
}

export const slideBase = css`
	position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
`;

export const slidesBase = css`
	display: flex;
	flex-direction: row;
  position: relative;
  overflow: hidden;
	user-select: none;
  touch-action: pan-y;

	&[data-keen-slider-moves] * {
		pointer-events: none;
	}
`;

export const useSlider = ({ slidesCount, slidesPerView = 1, autoScroll = true, spacing = 0, vertical = false, loop = true, interval = 5000 }: Props) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [pause, setPause] = React.useState(false);
	const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
		slides: '.slide',
		loop,
		spacing,
		vertical,
		slidesPerView,
		dragStart: () => {
			setPause(true);
		},
		dragEnd: () => {
			setPause(false);
		},
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide);
		}
	});
	const paginated = slidesCount > slidesPerView;

	useEffect(() => {
		sliderRef.current?.addEventListener('mouseover', () => {
			setPause(true);
		});
		sliderRef.current?.addEventListener('mouseout', () => {
			setPause(false);
		});
	}, [sliderRef]);

	useEffect(() => {
		if (autoScroll && paginated) {
			const timeout = setInterval(() => {
				if (!pause && slider) {
					slider.next();
				}
			}, interval);
			return () => clearInterval(timeout);
		}
	}, [pause, slider, paginated, interval, autoScroll]);

	return { sliderRef, slider, currentSlide };
};