import { Hero } from '../components/Hero';
import { Products } from '../components/Products';
import { Materials } from '../components/Materials';
import { Banner } from '../components/Banner';
import { db, doc, collection } from '../firebase';

export const CommunicationPage = () =>
	<div>
		<Hero res={{ base: doc(db, 'communication/hero'), items: collection(db, 'communication/hero/items') }} />
		<Products
			section="moje-doswiadczenie"
			res={{
				base: doc(db, 'communication/offer'), localized: doc(db, 'communication/offer/localized/pl-PL'),
				items: collection(db, 'communication/offer/items')
			}}
		/>
		<Banner res={{ base: doc(db, 'communication/cooperation'), localized: doc(db, 'communication/cooperation/localized/pl-PL') }} />
		<Materials res={{ base: doc(db, 'communication/materials'), localized: doc(db, 'communication/materials/localized/pl-PL'), items: collection(db, 'communication/materials/items') }} />
	</div>