type Size = keyof typeof breakpoints;

const MIN_RESOLUTION = 0;
const MAX_RESOLUTION = 50000;
const base = 4;
export const values = {
    base,
    baseFontSize: 4 * base,
    baseline: 6 * base
};
export const breakpoints = {
    xs: { from: MIN_RESOLUTION, to: 599 },
    sm: { from: 600, to: 904 },
    md: { from: 905, to: 1239 },
    lg: { from: 1240, to: 1439 },
    xl: { from: 1440, to: MAX_RESOLUTION }
};
const getMediaQuery = (from: number, to: number) => `@media only screen and (min-width: ${from}px) and (max-width: ${to}px)`;
export const respondTo = (size: Size) => {
    const { from, to } = breakpoints[size];
    return getMediaQuery(from, to);
};
export const respondToMin = (size: Size) => getMediaQuery(breakpoints[size].from, MAX_RESOLUTION);
export const respondToMax = (size: Size) => getMediaQuery(MIN_RESOLUTION, breakpoints[size].to);
export const respondToMinMax = (minSize: Size, maxSize: Size) => getMediaQuery(breakpoints[minSize].from, breakpoints[maxSize].to);

export const getSize = (value: number) => Object.keys(breakpoints).find(size => value >= breakpoints[size as Size].from && value <= breakpoints[size as Size].to);
export const isSizeOf = (value: number, size: Size) => value >= breakpoints[size].from && value <= breakpoints[size].to;
export const isSizeOfMin = (value: number, size: Size) => value >= breakpoints[size].from;
export const isSizeOfMax = (value: number, size: Size) => value <= breakpoints[size].to;