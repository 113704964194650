import styled from 'styled-components';
import { Material } from './Material';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

type Order = string[];

interface CommonData {
	order: Order;
}

const defaultCommonData: CommonData = {
	order: []
};

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	className?: string;
}

const Container = styled.section`
	padding: 48px 0;
	background-color: rgba(217, 218, 249, 0.76);

	${respondToMax('sm')} {
		--columns: repeat(1, 280px);
	}
	${respondToMin('md')} {
		--columns: repeat( auto-fit, 232px);
	}
`;

const Title = styled.h2`
	margin: 0 0 48px;
	color: #262626;
	font-size: var(--section-font-size);
	font-weight: normal;
	text-transform: uppercase;
	text-align: center;
`;

const Collection = styled.div`
	display: grid;
	grid-gap: 32px;
	grid-template-columns: var(--columns);
	justify-content: center;
`;

export const Materials = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return common.order.length > 0
		? <Container className={className} data-testid="materials">
			<Title>{localized.heading}</Title>
			<Collection>
				{common.order.map((id: string) =>
					<Material key={id} res={{ base: doc(res.items, id), localized: doc(res.items, id, 'localized/pl-PL') }} />
				)}
			</Collection>
		</Container>
		: null;
}