import styled from 'styled-components';
import { Article } from '../components/Article';
import { db, doc } from '../firebase';

const dbSaleStatute = doc(db, 'statutes/sale');

const Page = styled.div`
	padding: 40px 0 56px;
`;

export const SaleStatute = () =>
	<Page>
		<Article res={{ base: dbSaleStatute, localized: doc(dbSaleStatute, 'localized/pl-PL') }} />
	</Page>