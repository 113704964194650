import React from 'react';
import styled, { css } from 'styled-components';
import { Slider, Slide } from './Slider';
import { respondToMin, respondToMax } from '../../styled/rwd';
import { FileData } from '../../hooks/useFile';

type Item = {
	id: string;
	author: string;
	body: string;
	image: FileData;
};

interface Props {
	items: Item[]
}

const Container = styled.section`
	${respondToMax('sm')} {
		--width: 100%;
		--padding: 0 var(--page-side-gutter);
	}
	${respondToMin('md')} {
		--width: 640px;
		--padding: 0;
	}

	box-sizing: border-box;
	width: var(--width);
	margin: 0 auto 32px;
	padding: var(--padding);
`;

const Verbatim = styled.div`
	${respondToMax('sm')} {
		--content-font-size: 1.45rem;
		--author-font-size: 1.3rem;
	}
	${respondToMin('md')} {
		--content-font-size: 1.7rem;
		--author-font-size: 1.5rem;
	}
`;

const Author = styled.p`
	margin: 0;
	font-size: var(--author-font-size);
  text-align: center;
`;

const Content = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	padding-bottom: 24px;
	margin-bottom: 24px;
	font-size: var(--content-font-size);
	text-align: center;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		height: 0;
		width: 150px;
		border-bottom: 1px solid #000;
	}

	p {
		display: inline;
		margin: 0;

		&:before,
		&:after {
			content: '"';
		}
	}
`;

const imageCommonCss = css`
    width: 104px;
	height: 104px;
	margin: 0 auto 8px;
	border-radius: 50%;
`;

const Image = styled.img`
	display: block;
	${imageCommonCss};
`;

const ImagePlaceholder = styled.div`
	${imageCommonCss};
    border: 4px dotted #ccc;
`;

export const Verbatims = ({ items }: Props) => {

	return <Container data-testid="verbatims">
		{items.length > 0 &&
			<Slider data-testid="slider">
				{items.map(({ id, author, body, image }) =>
					<Slide key={id} className="slide" data-testid="slide">
						<Verbatim data-testid="verbatim">
							{image?.url
								? <Image src={image.url} />
								: <ImagePlaceholder />
							}
							<Content>{body}</Content>
							<Author>{author}</Author>
						</Verbatim>
					</Slide>
				)}
			</Slider>
		}
	</Container>
}