import { css } from 'styled-components';

export const palette = {
	primary: { // niebieski
		main: '#809acf',
		light: '#809acf',
		dark: '#809acf',
		contrastText: '#fff'
	},
	secondary: { // fioletowy
		main: '#af86af',
		light: '#af86af',
		dark: '#af86af',
		contrastText: '#fff'
	},
	tertiary: {
		main: '#ededed',
		light: '#ededed',
		dark: '#ededed',
		contrastText: 'inherit'
	},
	// default: {
	// 	main: '#d0b2d5',
	// 	light: 'd0b2d5',
	// 	dark: 'd0b2d5',
	// 	contrastText: '#fff'
	// },
	default: { // czarny
		main: '#000',
		light: '#5c5c5c',
		dark: '#5c5c5c',
		contrastText: '#fff'
	},
};

export const colors = {
	button: {
		default: css`
			background-color: ${({ theme }) => theme.palette.default.light};

			&:hover {
				background-color: ${({ theme }) => theme.palette.default.base};
			}
		`
	}
};