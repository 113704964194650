import { getExtension } from '../tools';
import { storage, ref, uploadBytes, getDownloadURL, deleteObject } from '../firebase';

export interface FileData extends Partial<File> {
	url: string;
	path: string;
	uploadDate: string;
}

export const fileDefaultData: FileData = {
	url: '',
	path: '',
	name: '',
	type: '',
	size: 0,
	uploadDate: Date()
}

export const useFile = (name: string, path: string) => {
	
	const update = async (file: FileData) => {
		if(file instanceof File) {
			const fileName = `${name}.${getExtension(file.type)}`;
			const fullPath = `${path}/${fileName}`;
			const storageObject = ref(storage, fullPath);
			await uploadBytes(storageObject, file);
			const url = await getDownloadURL(storageObject);
			const data: FileData = {
				url,
				path: fullPath,
				name: fileName,
				type: file.type,
				size: file.size,
				uploadDate: Date()
			};
			return data;
		}
		return file;
	};

	const remove = (fullPath: string) => {
		const storageObject = ref(storage, fullPath);
		return deleteObject(storageObject);
	};

	return {update, remove};
};