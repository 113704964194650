import { useState } from 'react';

export const useLocalStorage = (key: string, initialValue: string) => {
    const [value, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? item : initialValue;
        } catch (error) {
            console.error(error);
            return initialValue;
        }
    });
    const setValue = (value: string) => {
        try {
            setStoredValue(value);
            window.localStorage.setItem(key, value);
        } catch (error) {
            console.error(error);
        }
    };
    return { value, setValue };
}