import React from 'react';
import styled from 'styled-components';
import { respondToMin, respondToMax } from '../styled/rwd';

interface Props {
	heading: string;
	phone: string;
	email: string;
}

const Container = styled.div`
    ${respondToMax('sm')} {
		--text-margin: var(--page-side-gutter);
		--details-justify-items: center;
		--heading-text-align: center;
	}
	${respondToMin('md')} {
		--text-margin: 0 0 0 48px;
		--details-justify-items: start;
		--heading-text-align: left;
	}
	margin: var(--text-margin);
`;

const Heading = styled.h2`
	font-size: 16px;
	font-weight: normal;
	text-transform: uppercase;
	text-align: var(--heading-text-align);
`;

const Details = styled.div`
	display: grid;
	grid-gap: 8px;
	justify-items: var(--details-justify-items);
	font-size: 14px;
	margin-bottom: 4px;
`;

const EmailLink = styled.a`
	${({ theme }) => theme.buttonContained({ iconStart: { name: 'email', color: '#fff' }, color: 'secondary' })};
	min-width: 80px;
`;

const PhoneLink = styled.a`
	${({ theme }) => theme.buttonContained({ iconStart: { name: 'phone', color: '#fff' }, color: 'secondary' })};
	min-width: 80px;
`;

export const ContactDetails = ({ heading, phone, email }: Props) =>
	<Container>
		<Heading>{heading}</Heading>
		<Details>
			<PhoneLink href={`tel:${phone}`}>Zadzwoń</PhoneLink>
			<EmailLink href={`mailto:${email}`}>Napisz</EmailLink>
		</Details>
	</Container>