import slugifyFunc from 'slugify';

export const getExtension = (type: string) => {
	switch (type) {
		case 'image/jpg':
		case 'image/jpeg':
			return 'jpg';

		case 'image/png':
			return 'png';

		case 'application/pdf':
			return 'pdf';

		default:
			console.warn('File EXTENSION has not been recognized');
			break;
	}
};

export const slugify = (phrase: string) => slugifyFunc(phrase, { lower: true, strict: true, locale: 'vi' });
