import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Heading, Image as ImageBase } from '@hamrolt/components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { DocumentReference } from '../firebase';

type Order = string[];

interface CommonData {
	order: Order;
	image: FileData;
}

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	link: string;
	className?: string;
}

const Container = styled(Link)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	color: inherit;
	text-decoration: none;
`;

const Title = styled(Heading)`
	position: absolute;
	display: block;
	margin: 0;
	padding: 8px 32px;
	font-size: 25px;
	text-transform: uppercase;
	color: #fff;
	text-align: center;
	background-color: ${({ theme }) => theme.textBackgrounds.dark};
`;

const Image = styled(ImageBase)`
	display: block;
	width: 100%;
`;

const defaultCommonData: CommonData = {
	order: [],
	image: fileDefaultData
};

export const Poster = ({ res, link, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} to={link}>
		<Title>{localized.heading}</Title>
		<Image src={common.image.url} />
	</Container>
}