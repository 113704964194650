import styled from 'styled-components';
import { FileData, fileDefaultData } from '../../hooks/useFile';
import { respondToMin, respondToMax } from '../../styled/rwd';

export type Direction = 'nw' | 'n' | 'ne' | 'e' | 'm' | 'w' | 'se' | 's' | 'sw';
export type Position = 'start' | 'center' | 'end';
export type Definitions = Record<Direction, { h: Position, v: Position }>;
export type Background = 'dark' | 'none';
export type Width = '33' | '50' | '75' | '100';

const positionerDefinitions: Definitions = {
	nw: { h: 'start', v: 'start' },
	n: { h: 'center', v: 'start' },
	ne: { h: 'end', v: 'start' },
	e: { h: 'start', v: 'center' },
	m: { h: 'center', v: 'center' },
	w: { h: 'end', v: 'center' },
	se: { h: 'start', v: 'end' },
	s: { h: 'center', v: 'end' },
	sw: { h: 'end', v: 'end' }
};

interface Props {
	body: string;
	image: FileData;
	imageAlt: string;
	width: Width;
	direction: Direction;
	background: Background;
	className?: string;
}

const Container = styled.div<{ $position: { h: Position, v: Position } }>`
	${respondToMax('sm')} {
		--font-size: 1rem;
		--texts-padding: 7px 35px;
	}

	${respondToMin('md')} {
		--font-size: 2.8rem;
		--texts-padding: 17px 88px;
	}

	display: grid;
	justify-items: ${({ $position }) => $position.h};
	align-items: ${({ $position }) => $position.v};
	position: relative;
`;

const Texts = styled.div<{ $width: Width, $background: Background }>`
	box-sizing: border-box;
	position: absolute;
	width: ${({ $width }) => $width}%;
	padding: var(--texts-padding);
	color: #fff;
	background-color: ${({ $background, theme }) => theme.textBackgrounds[$background]};
`;

const Body = styled.div`
	font-size: var(--font-size);
	margin-bottom: 8px;
`;

const Image = styled.img`
	display: block;
	width: 100%;
`;

export const Hero = ({ body, image, imageAlt, width, direction, background, className }: Props) =>
	<Container className={className} $position={positionerDefinitions[direction]}>
		{body &&
			<Texts $width={width} $background={background}>
				<Body>{body}</Body>
			</Texts>
		}
		<Image src={image.url} alt={imageAlt} />
	</Container>