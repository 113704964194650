import styled from 'styled-components';
import { Product } from './Product/Product';
import { useDocument } from '../hooks/useDocument';
import { doc, DocumentReference, CollectionReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

type Order = string[];

interface CommonData {
	order: Order;
}

const defaultCommonData: CommonData = {
	order: []
};

interface LocalizedData {
	heading: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: ''
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
		items: CollectionReference;
	};
	section: string;
	className?: string;
}

const Container = styled.div`
	margin-bottom: 104px;

	${respondToMax('sm')} {
		--columns: repeat(1, 280px);
	}
	${respondToMin('md')} {
		--columns: repeat(4, 232px);
	}
`;

const Collection = styled.div`
	display: grid;
	grid-gap: 32px;
	grid-template-columns: var(--columns);
	justify-content: center;
`;

const Header = styled.header`
	margin: 0 0 48px;
	padding: 48px 0;
	background-color: ${({ theme }) => theme.palette.tertiary.main};
`;

const Title = styled.h2`
	display: block;
	width: 100%;
	margin: 0;
	color: ${({ theme }) => theme.palette.tertiary.contrastText};
	font-size: var(--section-font-size);
	font-weight: normal;
	text-transform: uppercase;
	text-align: center;
`;

export const Products = ({ res, section, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className} data-testid="products">
		<Header>
			<Title>{localized.heading}</Title>
		</Header>
		<Collection>
			{common.order.map((id: string) =>
				<Product
					key={id}
					section={section}
					res={{ base: doc(res.items, id), localized: doc(res.items, id, 'localized/pl-PL') }}
				/>
			)}
		</Collection>
	</Container >
}