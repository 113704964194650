import { useContext } from 'react';
import styled from 'styled-components';
import { AppSettingsContext, SocialChannelName } from '../AppSettings';

const Container = styled.div`
	display: grid;
	grid-gap: 16px;
	grid-auto-flow: column;
	justify-content: center;
`;

const SocialMediaButton = styled.a<{ $name: SocialChannelName; }>`
	${({ $name, theme }) => theme.iconButton({ icon: { name: $name, color: '#cecece' } })};
`;

export const SocialChannels = () => {
	const { socialChannels } = useContext(AppSettingsContext);

	return <Container>
		{Object.entries(socialChannels).map(([channelId, { label, url }]) =>
			<SocialMediaButton key={channelId} $name={channelId as SocialChannelName} href={url}>{label}</SocialMediaButton>)
		}
	</Container>
};