import styled from 'styled-components';
import { Article } from '../components/Article';
import { db, doc } from '../firebase';

const dbPrivacyPolicy = doc(db, 'privacy/policy');

const Page = styled.div`
	padding: 40px 0 56px;
`;

export const PrivacyPolicy = () =>
	<Page>
		<Article res={{ base: dbPrivacyPolicy, localized: doc(dbPrivacyPolicy, 'localized/pl-PL') }} />
	</Page>