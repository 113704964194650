import React, { useContext } from 'react';
import styled from 'styled-components';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { AppSettingsContext } from '../AppSettings';
import { Image as ImageBase } from '@hamrolt/components';
import { ContactDetails } from './ContactDetails';
import { respondToMin, respondToMax } from '../styled/rwd';

interface LocalizedData {
	phone: string;
	email: string;
}

const defaultLocalizedData: LocalizedData = {
	phone: '',
	email: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

const Container = styled.div`
	${respondToMax('sm')} {
		--columns: 1fr;
		--text-margin: var(--page-side-gutter);
		--image-wrapper-width: 100vw;
	}
	${respondToMin('md')} {
		--columns: 500px 400px;
		--text-margin: 0 0 0 48px;
		--image-wrapper-width: 50vw;
	}

	display: grid;
	grid-template-columns: var(--columns);
	justify-content: center;
	align-items: center;
`;

const ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: var(--image-wrapper-width);
	overflow: hidden;
`;

const Image = styled(ImageBase)`
	grid-area: image;
	display: block;
`;

export const ContactInfo = () => {
	const { contactData } = useContext(AppSettingsContext);

	return <Container>
		<ContactDetails
			heading="Skontaktuj się ze mną, porozmawiajmy."
			phone={contactData.phone}
			email={contactData.email}
		/>
		<ImageWrapper>
			<Image src={contactData.imageContact.url} />
		</ImageWrapper>
	</Container>
};