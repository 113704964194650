import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { ProductDetails } from '../components/Product/ProductDetails';
import { sectionsMap } from '../App';
import { db, doc } from '../firebase';

const Page = styled.div`
	display: grid;
	grid-gap: 48px;
	padding: 0 0 40px;
`;

const unslugify = (slug: string) => {
    const slugChunks = slug.split('-');
    return slugChunks[slugChunks.length-1];
};

export const ProductPage = () => {
    const { sectionId, slug } = useParams<{ sectionId: keyof typeof sectionsMap; slug: string }>();
    const dbProduct = doc(db, `${sectionsMap[sectionId]}/offer/items/${unslugify(slug)}`);

    return <Page data-testid="page">
        <ProductDetails res={{ base: dbProduct, localized: doc(dbProduct, 'localized/pl-PL') }} />
    </Page>
}