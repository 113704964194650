import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

type LinkProps = {
	id: string;
	to: string;
	label?: string;
	icon?: string;
};

interface NavigationProps {
	className?: string;
	links: LinkProps[];
	onClick?: () => void;
}

const Link = styled(RouterLink)`
	display: block;
	padding: 5px 15px;
	color: #696969;
	font-size: 16px;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	overflow: hidden;
	cursor: pointer;
	white-space: nowrap;
	outline: none;

	&:hover {
		color: #fff;
		background-color: ${({ theme }) => theme.palette.secondary.main};
	}
`;

export const Navigation = ({ className, links, onClick }: NavigationProps) =>
	<nav className={className} data-testid="navigation">
		{links.map(link => <Link key={link.id} to={link.to} onClick={onClick}>{link.label}</Link>)}
	</nav>