import styled from 'styled-components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { DocumentReference } from '../firebase';

interface CommonData {
	published: boolean;
	image: FileData;
	link: string;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData,
	link: ''
};

interface Props {
	res: {
		base: DocumentReference;
	};
	className?: string;
}

const Link = styled.a`
	display: block;
	position: relative;
	width: 128px;
	height: 128px;
	margin: 0 auto;
`;

const Image = styled.img`
	display: block;
	width: 100%;
	height: 100%;
`;

export const Logo = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);

	return <Link className={className} href={common.link} target="_blank">
		<Image src={common.image.url} />
	</Link>
};