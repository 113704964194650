import styled from 'styled-components';
import { ImageArticle } from '../components/ImageArticle';
import { ContactInfo } from '../components/ContactInfo';
import { db, doc } from '../firebase';

const Page = styled.div`
	padding-top: 40px;
`;

export const AboutPage = () =>
	<Page>
		<ImageArticle res={{ base: doc(db, 'about/info'), localized: doc(db, 'about/info/localized/pl-PL') }} />
		<ContactInfo />
	</Page>