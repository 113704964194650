import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage, StorageReference } from "firebase/storage";

export * from 'firebase/firestore';

export { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
export type StorageRef = StorageReference;

initializeApp({
	apiKey: "AIzaSyAaKLwkAdzBOnj854K1reZiLspdXd9qT2o",
	authDomain: "projekt-ij.firebaseapp.com",
	projectId: "projekt-ij",
	storageBucket: "projekt-ij.appspot.com",
	messagingSenderId: "683967621325",
	appId: "1:683967621325:web:80194bc8281f5ae165e184",
	measurementId: "G-H51H7X1D1V"
});

export const analytics = getAnalytics();
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();