import styled from 'styled-components';
import { Image as ImageBase } from '@hamrolt/components';
import { useDocument } from '../hooks/useDocument';
import { FileData, fileDefaultData } from '../hooks/useFile';
import { DocumentReference } from '../firebase';
import { respondToMin, respondToMax } from '../styled/rwd';

interface LocalizedData {
	heading: string;
	bodyHTML: string;
}

const defaultLocalizedData: LocalizedData = {
	heading: '',
	bodyHTML: ''
};

interface CommonData {
	published: boolean;
	image: FileData;
}

const defaultCommonData: CommonData = {
	published: false,
	image: fileDefaultData
};

interface Props {
	res: {
		base: DocumentReference;
		localized: DocumentReference;
	};
	className?: string;
}

const Container = styled.div`
	${respondToMax('sm')} {
		--columns: 1fr;
		--areas: "image" "text";
		--text-margin: var(--page-side-gutter);
	}
	${respondToMin('md')} {
		--columns: 500px 400px;
		--areas: "image text";
		--text-margin: 0 0 44px 48px;
	}

	display: grid;
	grid-template-columns: var(--columns);
	grid-template-areas: var(--areas);
	justify-content: center;
`;

const Image = styled(ImageBase)`
	grid-area: image;
	display: block;
	width: 100%;
`;

const RichText = styled.div`
	${({ theme }) => theme.richtext};
	letter-spacing: 0.05rem;
`;

const Text = styled.div`
	grid-area: text;
	margin: var(--text-margin);
`;

const Title = styled.h2`
	position: relative;
	padding: 0 0 24px;
	margin: 0 0 24px;
	font-size: 30px;
	font-weight: normal;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		height: 0;
		width: 32px;
		border-bottom: 1px solid #000;
	}
`;

export const ImageArticle = ({ res, className }: Props) => {
	const { data: common } = useDocument<CommonData>(res.base, defaultCommonData);
	const { data: localized } = useDocument<LocalizedData>(res.localized, defaultLocalizedData);

	return <Container className={className}>
		<Text>
			<Title>{localized.heading}</Title>
			<RichText dangerouslySetInnerHTML={{ __html: localized.bodyHTML }} />
		</Text>
		<Image src={common.image.url} />
	</Container>
}