import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppSettingsContext } from '../AppSettings';
import { SocialChannels } from './SocialChannels';

const Container = styled.footer`
	padding: 96px 0 64px;
	text-align: center;
	color: #fff;
	background-color: #003052;
`;

const Link = styled.a`
	${({ theme }) => theme.link};
`;

const Rights = styled.div`
	font-family: 'Montserrat';
`;

const Name = styled.div`
	${({ theme }) => theme.typography.heading6};
	margin-bottom: 8px;
`;

const Contact = styled.div`
	font-family: 'Montserrat';
	margin-bottom: 4px;
`;

export const Footer = () => {
	const { contactData } = useContext(AppSettingsContext);

	return <Container>
		<SocialChannels />
		<Name>Izabela&nbsp;Janowska</Name>
		<Contact>T:&nbsp;<Link href={`tel:${contactData.phone}`}>{contactData.phone}</Link> &middot; E:&nbsp;<Link href={`mailto:${contactData.email}`}>{contactData.email}</Link></Contact>
		<Link href="/polityka-prywatnosci">Polityka Prywatności</Link>
		&nbsp;&middot;&nbsp;
		<Link href="/regulamin-sprzedazy">Regulamin Sprzedaży</Link>
		<Rights>© {new Date().getFullYear()} Wszelkie prawa zastrzeżone</Rights>
	</Container>
}